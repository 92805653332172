import styled from "@emotion/styled";

import { ButtonSolidToOutline } from "src/styles/buttons";
import * as colors from "src/styles/constants/colors";

const NewRoom = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1px;
  overflow: hidden;
`;

const Button = styled(ButtonSolidToOutline)`
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: none;
  gap: 6px;
  height: 24px;
  padding: 2px 8px;
  border-radius: ${props =>
    props.rounded === "left" ? "4px 0 0 4px" : "0 4px 4px 0"};

  &:hover,
  :focus {
    border-color: ${colors.lightBlue500};
    background-color: ${colors.lightBlue500};
  }
`;

const DropDownButton = styled(Button)`
  padding: 2px;
  width: 26px;
`;

export { NewRoom, Button, DropDownButton };
