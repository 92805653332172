// @flow

import React from "react";

const HollowPlus = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    strokeWidth="0.2"
  >
    <path
      d="M7.25 12H8.75V8.75H12V7.25H8.75V4H7.25V7.25H4V8.75H7.25V12ZM8.00583 16C6.90472 16 5.86806 15.7917 4.89583 15.375C3.92361 14.9583 3.07292 14.3854 2.34375 13.6562C1.61458 12.9271 1.04167 12.0767 0.625 11.105C0.208333 10.1333 0 9.09514 0 7.99042C0 6.88569 0.208333 5.85069 0.625 4.88542C1.04167 3.92014 1.61458 3.07292 2.34375 2.34375C3.07292 1.61458 3.92333 1.04167 4.895 0.625C5.86667 0.208333 6.90486 0 8.00958 0C9.11431 0 10.1493 0.208333 11.1146 0.625C12.0799 1.04167 12.9271 1.61458 13.6562 2.34375C14.3854 3.07292 14.9583 3.92167 15.375 4.89C15.7917 5.85847 16 6.89319 16 7.99417C16 9.09528 15.7917 10.1319 15.375 11.1042C14.9583 12.0764 14.3854 12.9271 13.6562 13.6562C12.9271 14.3854 12.0783 14.9583 11.11 15.375C10.1415 15.7917 9.10681 16 8.00583 16ZM8 14.5C9.80556 14.5 11.3403 13.8681 12.6042 12.6042C13.8681 11.3403 14.5 9.80556 14.5 8C14.5 6.19444 13.8681 4.65972 12.6042 3.39583C11.3403 2.13194 9.80556 1.5 8 1.5C6.19444 1.5 4.65972 2.13194 3.39583 3.39583C2.13194 4.65972 1.5 6.19444 1.5 8C1.5 9.80556 2.13194 11.3403 3.39583 12.6042C4.65972 13.8681 6.19444 14.5 8 14.5Z"
      fill="white"
    />
  </svg>
);

export default HollowPlus;
